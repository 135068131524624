<template>
  <monitoring-items
    :activeModules="activeModules"
    :mapItems="mapParliamentResolutionsItems"
    :api="{
      moduleId: 'pk_reg',
      modulesWithName: 'custom_query_parliment_resolution_with_names',
      modules: 'module_parliment_resolution_header',
      favoriteHated: 'favorite_and_hated_parliment_resolutions_header',
      favoriteHatedResponse: 'favorite_and_hateful_parliment_resolutions_list',
      bookmarks: 'bookmarks/fetchParliamentResolutions',
    }"
    #default="{items}"
  >
    <parliament-resolutions-list :items="items" />
  </monitoring-items>
</template>

<script>
import MonitoringItems from '../components/MonitoringItems';
import ParliamentResolutionsList from '../../ParliamentResolutions/ParliamentResolutionsList';
import { mapParliamentResolutionsItems } from '../../ParliamentResolutions/mapParliamentResolutionsItems';

export default {
  props: {
    activeModules: Array,
  },
  components: {
    MonitoringItems,
    ParliamentResolutionsList,
  },
  metaInfo() {
    return {
      title: 'Monitoring uchwał Sejmu i Senatu',
    };
  },
  data() {
    return {
      mapParliamentResolutionsItems,
    };
  },
};
</script>
